import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../shared/crud.service';    // CRUD services API
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'; // Reactive form services
import { ToastrService } from 'ngx-toastr'; // Alert message using NGX toastr
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-consultor',
  templateUrl: './add-consultor.component.html',
  styleUrls: ['./add-consultor.component.css']
})
export class AddConsultorComponent implements OnInit {
 
  public consultorForm: FormGroup;  // Define FormGroup to student's form
  Occupation: any = [
    'Agriculture','Animal science','Business','Cosmetology','Customer service','Creative','Education','Engineering','Finance','Healthcare','Hospitality','Human resources','Information technology','Leadership','Marketing','Operations','Sales'
  ];

  constructor(
    public crudApi: CrudService,  // CRUD API services
    public fb: FormBuilder,       // Form Builder service for Reactive forms
    public toastr: ToastrService,  // Toastr service for alert message
    public router:Router // Router
  ) { }

 
  ngOnInit() {
    this.crudApi.GetConsultantList(); 
    this.consultForm();              // Call student form when component is ready
  }


  consultForm() {
    this.consultorForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      occupationCategory:['', [Validators.required]],
      tier:['', [Validators.required]],
      vehicle:['', [Validators.required]],
      basedProvince: ['', [Validators.required]],
      
    })  
  }

  // Accessing form control using getters
  get firstName() {
    return this.consultorForm.get('firstName');
  }

  get lastName() {
    return this.consultorForm.get('lastName');
  }  

  get email() {
    return this.consultorForm.get('email');
  }

  get mobileNumber() {
    return this.consultorForm.get('mobileNumber');
  }

  get occupationCategory() {
    return this.consultorForm.get('occupationCategory');
  }

   get tier() {
    return this.consultorForm.get('tier');
  }

  get vehicle() {
    return this.consultorForm.get('vehicle');
  }

  get basedProvince() {
    return this.consultorForm.get('basedProvince');
  }

  // Choose city using select dropdown
  changeOccupation(e) {
    this.occupationCategory.setValue(e.target.value, {
      onlySelf: true
    })
  }

  // Reset student form's values
  ResetForm() {
    this.consultorForm.reset();
  }  

   async submitConsultantData() {
     console.log(this.consultorForm.value)
    this.crudApi.AddConsultor(this.consultorForm.value); // Submit student data using CRUD API
    this.toastr.success(this.consultorForm.controls['firstName'].value + ' successfully added!'); // Show success message when data is successfully submited
    this.ResetForm();  // Reset form when clicked on reset button
    this.router.navigate(['/consultors/']);
   };
}