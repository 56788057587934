import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../shared/crud.service';    // CRUD services API
import { ToastrService } from 'ngx-toastr'; // Alert message using NGX toastr
import { Consultor } from '../../shared/consultor';   // Student interface class for Data types.
import { MessagingService } from '../../service/messaging.service';


@Component({
  selector: 'app-consultors',
  templateUrl: './consultors.component.html',
  styleUrls: ['./consultors.component.css']
})
export class ConsultorsComponent implements OnInit {
  p: number = 1;     
  message;                 // Settup up pagination variable
  Consultor: Consultor[];                 // Save students data in Student's array.
  hideWhenNoStudent: boolean = false; // Hide students data table when no student.
  hideWhenConsultant: boolean = false; // Hide students data table when no student.
  noData: boolean = false;            // Showing No Student Message, when no student in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  consultantCounter:number;

  constructor(
    public crudApi: CrudService, // Inject student CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message
    private messagingService: MessagingService
    ){ }


  ngOnInit() {
  this.messagingService.requestPermission()
  this.messagingService.receiveMessage()
  this.message = this.messagingService.currentMessage
    this.dataState(); // Initialize student's list, when component is ready
    let s = this.crudApi.GetConsultantList(); 
    s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata($key)
      this.Consultor = [];
      data.forEach(item => {
        let a = item.payload.toJSON(); 
        a['$key'] = item.key;
        this.Consultor.push(a as Consultor);
      })
    });
  }

  // Using valueChanges() method to fetch simple list of students data. It updates the state of hideWhenNoStudent, noData & preLoader variables when any changes occurs in student data list in real-time.
  dataState() {     
    this.crudApi.GetConsultantList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenConsultant = false;
        this.noData = true;
      } else {
        this.hideWhenConsultant = true;
        this.noData = false;
      }
    })
  }

  // Method to delete student object
  deleteConsultant(consultor) {
    if (window.confirm('Are sure you want to delete this Consultant ?')) { // Asking from user before Deleting student data.
      this.crudApi.DeleteStudent(consultor.$key) // Using Delete student API to delete student.
      this.toastr.success(consultor.firstName + ' successfully deleted!'); // Alert message will show up when student successfully deleted.
    }
  }
}