import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  title = '"Joint Dots Application"';


  email: string;
  password: string;


  constructor(private authService: AuthService,
    private router: Router,
  ) { }

  async login() {
    this.authService.login(this.email, this.password);
  }

  async logout() {
    console.log('User is successfully logged out.')
    this.authService.logout();
  }

  ngOnInit(): void {
  }

}
