import { Injectable } from '@angular/core';
import { Router } from  "@angular/router";
import { AngularFireAuth } from  "@angular/fire/auth";
import { auth, User } from  'firebase';
import * as firebase from 'firebase/app';
 
// For try-catch mechanism.
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user:  User;
  // user: Observable<firebase.User>;
  err: String;
    
  constructor(public afAuth:AngularFireAuth, public router:Router) {
    this.afAuth.authState.subscribe(user => {
      if (user){
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    })
   }


  // To login a valid user.
  async login(emailAddress: string, password: string) {
    this.afAuth.signInWithEmailAndPassword(emailAddress, password).then(value => {
        // console.log('User successfully logged in!');
        this.router.navigateByUrl('dashboard');
      })
      .catch(error => {
        this.err = error.message;
        console.log('Something went wrong:', error);
      });
  }

  async register(email: string, password: string) {
    var result = await this.afAuth.createUserWithEmailAndPassword(email, password)
    this.sendEmailVerification();
  }

  async sendEmailVerification() {
    await (await this.afAuth.currentUser).sendEmailVerification()
    this.router.navigate(['login/verify-email']);
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
 }

 async logout(){
  await this.afAuth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  get isLoggedIn(): boolean {
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
  }

  async  loginWithGoogle(){
    await  this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
    this.router.navigate(['dashboard']);
}
}
