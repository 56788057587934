import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { Consultor } from '../../shared/consultor';   // Student interface class for Data types.
import { ToastrService } from 'ngx-toastr';      // Alert message using NGX toastr
import { Agenda } from '../../shared/Agenda';
import { AgendaService } from '../../shared/agenda.service';


@Component({
  selector: 'dashboard-cmp',
  styleUrls: ['./dashboard.component.css'],
  templateUrl: 'dashboard.component.html'
})

export class DashboardComponent implements OnInit{

  AgendasArray = [];
  Consultor: Consultor[];                
  tasks:number;
  consultees:number;
  hideWhenNoAgenda: boolean = false; 
  noData: boolean = false;            
  preLoader: boolean = true;
  

  constructor(
    public crudApi: CrudService, 
    private apiService: AgendaService,
    public toastr: ToastrService, 
    
    ){ }


    ngOnInit() {
      this.fetchAgendas();
      this.fetchConsultees();
      this.dataState();
      let bookingRes = this.apiService.getAgendaList();
      bookingRes.snapshotChanges().subscribe(res => {
        this.AgendasArray = [];
        res.forEach(item => {
          let a = item.payload.toJSON();
          a['$key'] = item.key;
          this.AgendasArray.push(a as Agenda);
        })
        this.dataState();
      })
  
    }
  
    fetchAgendas() {
      this.apiService.getAgendaList().valueChanges().subscribe(res => {
        console.log('Fetched agendas list!')
        console.log(this.AgendasArray)

        this.tasks = res.length;
      })
    }

    fetchConsultees() {
      this.crudApi.GetConsultantList().valueChanges().subscribe(data => {
        console.log('Fetched consultees list!')
        this.consultees = data.length;
      })
    }
  
  
    deleteAgenda(id) {
      this.apiService.deleteAgenda(id)
    }

  dataState() {     
      if(this.AgendasArray.length <= 0){
        this.hideWhenNoAgenda = false;
        this.noData = true;
        this.preLoader = true
        
      } else {
        this.hideWhenNoAgenda = true;
        this.noData = false;
        this.preLoader = false
      }
  }

}
