import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CrudService } from '../../shared/crud.service';
import { ActivatedRoute, Router } from "@angular/router"; // ActivatedRoue is used to get the current associated components information.
import { Location } from '@angular/common';  // Location service is used to go back to previous component
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-consultor',
  templateUrl: './edit-consultor.component.html',
  styleUrls: ['./edit-consultor.component.css']
})
export class EditConsultorComponent implements OnInit {

  editForm: FormGroup;  // Define FormGroup to student's edit form
  Occupation: any = [
    'Agriculture','Animal science','Business','Cosmetology','Customer service','Creative','Education','Engineering','Finance','Healthcare','Hospitality','Human resources','Information technology','Leadership','Marketing','Operations','Sales'
  ];
  
  constructor(
    private crudApi: CrudService,       // Inject CRUD API in constructor
    private fb: FormBuilder,            // Inject Form Builder service for Reactive forms
    private location: Location,         // Location service to go back to previous component
    private actRoute: ActivatedRoute,   // Activated route to get the current component's inforamation
    private router: Router,             // Router service to navigate to specific component
    private toastr: ToastrService       // Toastr service for alert message
  ){ }

  ngOnInit() {
    this.updateConsultorData();   // Call updateStudentData() as soon as the component is ready 
    const id = this.actRoute.snapshot.paramMap.get('id');  // Getting current component's id or information using ActivatedRoute service
    this.crudApi.GetConsultor(id).valueChanges().subscribe(data => {
      this.editForm.setValue(data)  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
    })
  }

  // Accessing form control using getters
  get firstName() {
    return this.editForm.get('firstName');
  }

  get lastName() {
    return this.editForm.get('lastName');
  }  

  get email() {
    return this.editForm.get('email');
  }

  get mobileNumber() {
    return this.editForm.get('mobileNumber');
  }

  get occupationCategory() {
    return this.editForm.get('occupationCategory');
  }

   get tier() {
    return this.editForm.get('tier');
  }

  get vehicle() {
    return this.editForm.get('vehicle');
  }

  get basedProvince() {
    return this.editForm.get('basedProvince');
  }

  // Contains Reactive Form logic
  updateConsultorData() {
    this.editForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      occupationCategory:['', [Validators.required]],
      tier:['', [Validators.required]],
      vehicle:['', [Validators.required]],
      basedProvince: ['', [Validators.required]],
    })
  }

   // Choose city using select dropdown
   changeOccupation(e) {
    this.occupationCategory.setValue(e.target.value, {
      onlySelf: true
    })
  }

  // Reset student form's values
  ResetForm() {
    this.editForm.reset();
  } 

  // Go back to previous component
  goBack() {
    this.location.back();
  }

  // Below methods fire when somebody click on submit button
  async updateForm(){
    this.crudApi.UpdateConsultor(this.editForm.value);       // Update student data using CRUD API
    this.toastr.success(this.editForm.controls['firstName'].value + ' updated successfully');   // Show succes message when data is successfully submited
    this.router.navigate(['/consultors/']);               // Navigate to student's list page when student data is updated
  }

}
