import { Injectable } from '@angular/core';
import { Student } from '../shared/student';  // Student data type interface class
import { Consultor } from '../shared/consultor';  // Student data type interface class
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object

@Injectable({
  providedIn: 'root'
})

export class CrudService {
  consultorsRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
  consultorRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too
  agendasRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
  agendaRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too
  
  
  // Inject AngularFireDatabase Dependency in Constructor
  constructor(private db: AngularFireDatabase) { }

   // Create Student
   AddConsultor(consultor: Consultor) {
    this.consultorsRef.push({
      firstName: consultor.firstName,
      lastName: consultor.lastName,
      email: consultor.email,
      mobileNumber: consultor.mobileNumber,
      occupationCategory: consultor.occupationCategory,
      tier:consultor.tier,
      vehicle:consultor.vehicle,
      basedProvince: consultor.basedProvince
    })
  }

    // Fetch Single Student Object
    GetConsultor(id: string) {
      this.consultorRef = this.db.object('consultor-list/' + id);
      return this.consultorRef;
    }
  
    // Fetch Students List
    GetConsultantList() {
      this.consultorsRef = this.db.list('consultor-list');
      return this.consultorsRef;
    }  


    // Fetch Students List
    GetAgendaList() {
      this.agendasRef = this.db.list('agenda-list');
      return this.agendasRef;
    }  



  //Update Student Object
  UpdateConsultor(consultor: Consultor) {
    this.consultorRef.update({
      firstName: consultor.firstName,
      lastName: consultor.lastName,
      email: consultor.email,
      mobileNumber: consultor.mobileNumber,
      occupationCategory: consultor.occupationCategory,
      tier:consultor.tier,
      vehicle:consultor.vehicle,
      basedProvince: consultor.basedProvince
    })
  }  


  // Delete Student Object
  DeleteStudent(id: string) { 
    this.consultorRef = this.db.object('consultor-list/'+id);
    this.consultorRef.remove();
  }
  
}