// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyCuG27jZbMl80yuuSd7TRIkENt-P8gA_ok",
    authDomain: "jointdots-f880b.firebaseapp.com",
    databaseURL: "https://jointdots-f880b.firebaseio.com",
    projectId: "jointdots-f880b",
    storageBucket: "jointdots-f880b.appspot.com",
    messagingSenderId: "463383525363",
    appId: "1:463383525363:web:1cdd6426b1f57065988486",
    measurementId: "G-S7JCC7CJHW"
  }
};