import { Injectable } from '@angular/core';
import { Agenda } from './Agenda';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})

export class AgendaService {
  agendaList: AngularFireList<any>;
  agendaRef: AngularFireObject<any>;

  constructor(private db: AngularFireDatabase) { }

  // Create
  createAgenda(agenda: Agenda) {
    return this.agendaList.push({
      agendaName: agenda.agendaName,
      siteProvince: agenda.siteProvince,
      siteName: agenda.siteName,
      siteAddress: agenda.siteAddress,
      tier_level: agenda.tier_level,
      siteDetails: agenda.siteDetails,
      car_required: agenda.car_required
    })
  }


  // Get single object
  getAgenda(id: string) {
    this.agendaRef = this.db.object('/agenda/' + id);
    return this.agendaRef;
  }

  // Get List
  getAgendaList() {
    this.agendaList = this.db.list('/agenda');
    return this.agendaList;
  }

  // Update
  updateAgenda(id, agenda: Agenda) {
    return this.agendaRef.update({
      agendaName: agenda.agendaName,
      siteProvince: agenda.siteProvince,
      siteName: agenda.siteName,
      siteAddress: agenda.siteAddress,
      tier_level: agenda.tier_level,
      siteDetails: agenda.siteDetails,
      car_required: agenda.car_required
    })
  }

  // Delete
  deleteAgenda(id: string) {
    this.agendaRef = this.db.object('/agenda/' + id);
    this.agendaRef.remove();
  }
}