import { NgModule } from '@angular/core';
// Use RouterModule, Routes for activating routing in angular
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AuthguardGuard } from './authguard.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ConsultorsComponent } from './pages/consultors/consultors.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { HistoryComponent } from './pages/history/history.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { LoginComponent } from './pages/login/login.component';
import { EditConsultorComponent } from './pages/edit-consultor/edit-consultor.component';
import { AddConsultorComponent } from './pages/add-consultor/add-consultor.component';
import { RegisterComponent } from './pages/register/register.component';


export const AppRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthguardGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthguardGuard] },
      { path: 'consultors', component: ConsultorsComponent, canActivate: [AuthguardGuard] },
      { path: 'add-consultor', component: AddConsultorComponent, canActivate: [AuthguardGuard] },
      { path: 'edit-consultor/:id', component: EditConsultorComponent, canActivate: [AuthguardGuard] },
      { path: 'locations', component: LocationsComponent, canActivate: [AuthguardGuard] },
      { path: 'history', component: HistoryComponent, canActivate: [AuthguardGuard] },
      { path: 'documents', component: DocumentsComponent, canActivate: [AuthguardGuard] },
    ]
  },
]

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
