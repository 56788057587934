import { NgModule } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.module';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/login/login.component';
// Importing the application’s environment file.
import { environment } from '../environments/environment';
 
// Angular Firebase module changes.
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MessagingService } from './service/messaging.service';
 
// Authentication service.
import { AuthService } from './auth/auth.service';
import { RegisterComponent } from './pages/register/register.component';
import { ConsultorsComponent } from './pages/consultors/consultors.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HistoryComponent } from './pages/history/history.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { AppRoutingModule } from './app-routing.module';
import { EditConsultorComponent } from './pages/edit-consultor/edit-consultor.component';
import { AddConsultorComponent } from './pages/add-consultor/add-consultor.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    DashboardComponent,
    RegisterComponent,
    ConsultorsComponent,
    HistoryComponent,
    LocationsComponent,
    DocumentsComponent,
    EditConsultorComponent,
    AddConsultorComponent
  ],
  imports: [
    
    RouterModule.forRoot(AppRoutes,{
      useHash: false
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  providers: [AuthService,MessagingService,AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
