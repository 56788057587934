import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
    { path: '/consultors',    title: 'Consultors',        icon:'nc-badge',    class: '' },
    { path: '/locations',     title: 'Locations',         icon:'nc-pin-3',      class: '' },
    { path: '/history',     title: 'History', icon:'nc-time-alarm',    class: '' },
    { path: '/documents',          title: 'Documents', icon:'nc-single-copy-04',  class: '' },
];


@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
