import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  loginbtn:boolean;
  logoutbtn:boolean;
  
    title = 'frontend';
  
    constructor(private authService: AuthService,private router: Router) {
      
        if(this.authService.isLoggedIn)
          {
            this.router.navigate(['/dashboard']);
            this.loginbtn=false;
            this.logoutbtn=true
          }else{
            this.loginbtn=true;
            this.logoutbtn=false
          }
        }
  
      // private changeName(name: boolean): void {
      //   this.logoutbtn = name;
      //   this.loginbtn = !name;
      // }
      
      logout(){
        this.authService.logout;
        window.location.href = window.location.href;
      }
}
